import React from "react";
import "../style/comoVender.css"

function Vender() {
    return (
        <div className="mae-comoVender">
            <div className="bloco-faq">
                <div className='bloco-foto-faq'>
                    <div className='background-faq'>
                        <div className='texto-foto-faq'>
                            <h3 className='h3 animacao2'>Saiba como vender</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clientes">
                <p>Prezados Clientes</p>
                <ul className="ul-vender">
                    <li className="li-vender">Leilão é a forma de negociação mais democrática, rápida e segura. É público, aberto a qualquer interessado e fiscalizado pela Junta Comercial do Estado.</li>
                    <li className="li-vender">Para cada leilão é elaborado um regulamento específico estabelecendo todas as condições de venda.</li>
                    <li className="li-vender">Em leilão vendem-se bens em qualquer estado de conservação, nacionais ou importados, tais como: equipamentos, materiais, imóveis, veículos de qualquer tipo, objetos de arte, sucatas, matéria-prima, etc.</li>
                </ul>
                <p>Oferecemos aos nossos clientes nossa completa estrutura e mais:</p>
                <ul className="ul-vender">
                    <li className="li-vender">Garantia de fé pública às transações;</li>
                    <li className="li-vender">Consultoria no planejamento dos leilões;</li>
                    <li className="li-vender">Avaliação de ativos e estratégia de venda;</li>
                    <li className="li-vender">Identificação e desenvolvimento do mercado comprador.</li>
                </ul>
                <p>Para saber como vender seus produtos em um leilão público, por favor, envie-nos seus dados para contato e aguarde o retorno!</p>
                <div className="botao-duvidas">
                    <h1>clique aqui para enviar seus dados</h1>
                    <button id="btn-duvidas"><a href="contatos">Clique aqui</a></button>
                </div>

            </div>






        </div>
    )
}

export default Vender;