import React from "react";
import '../style/duvidas.css';


function Duvidas() {

    return (
        <div className="mae-faq">
            <div className="bloco-faq">
                <div className='bloco-foto-faq'>
                    <div className='background-faq'>
                        <div className='texto-foto-faq'>
                            <h3 className='h3 animacao2'>Dúvidas sobre leilão</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='titulo-pagina'>
                <h1>Aqui você encontra as respostas para algumas das suas dúvidas</h1>
            </div>

            <div className="texto-inicio">
                <div className="texto-leilao">
                    <h3>LEILÃO</h3>
                    <div className="parte-inicio"><p>Leilão é um procedimento comercial e legal no qual bens ou propriedades são vendidos a compradores interessados por meio de lances competitivos. Esse processo é regulamentado por leis e regulamentos específicos, e o objetivo é garantir que a venda seja realizada de maneira justa e transparente.
                    </p>
                        <p>
                            É essencial verificar as regras e regulamentos específicos de cada leilão de veículos que ficam disponíveis nos editais, bem como entender as condições de pagamento e quaisquer taxas adicionais envolvidas.
                        </p>
                        <p>
                            Além disso, é aconselhável realizar uma inspeção completa do veículo antes de fazer um lance, esse momento é chamado de visitação publica dos lotes que ocorrerão sempre dias anteriores ao leilão.
                        </p>
                    </div>
                </div>
            </div>
            <div className="titulo-meio">
                <div className="texto-meio">
                    <div className="primeira-parte">
                        <h3>AOS ARREMATENTES</h3>
                        <p>Os arrematantes são parte essencial dos leilões, representando os interessados que desejam adquirir bens ou propriedades por meio de lances competitivos.</p>
                        <p>Eles desempenham um papel central no processo de leilão, determinando os preços finais e a alocação de ativos.
                        </p>
                        <p> Para participar o arrematantes deverão de cadastrar no sitio eletrônico direcionado pela Leiloeira de forma previa ou comparecer ao local que será realizado o certame.</p>
                    </div>
                </div>
            </div>
            <div className="botao-duvidas">
                <h1>Clique aqui para mandar uma mensagem caso tenha alguma dúvida</h1>
                <button id="btn-duvidas"><a href="contatos">Clique aqui</a></button>
            </div>

        </div>
    );
}

export default Duvidas;