import React from "react";
import "../style/contatos.css"

function Contatos() {
    return (
        <div className="mae-contatos">
            <div className="bloco-faq">
                <div className='bloco-foto-faq'>
                    <div className='background-faq'>
                        <div className='texto-foto-faq'>
                            <h3 className='h3 animacao2'>Entre em contato</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="titulo-pagina">
                <h1>Aqui você pode entrar em contato caso queira</h1>
            </div>
            <div className="titulo">
                <p> Escreva abaixo o assunto que você desejar</p>
            </div>
            <div className="tudo">
                <form action="https://formsubmit.co/leiloeiraelizabethamorim@gmail.com" method="POST">
                    <input type="text" name="nome" placeholder="Nome" required />
                    <input type="email" name="email" placeholder="E-mail" required />
                    <input type="tel" placeholder="Telefone" required />
                    <textarea name="message" placeholder="Digite sua mensagem" />
                    <input type="hidden" name="_captcha" value="false" />
                    <input type="hidden" name="_next" value="https://www.leiloeiraelizabethamorim.com/obrigado"></input>
                    <button type="submit">Enviar</button>
                </form>
            </div>
        </div>
    );
}

export default Contatos;