import { useState } from "react";
import "../style/cabecalho.css"
import { Link, Outlet } from "react-router-dom";


function Header() {

    const [menuAberto, setMenuAberto] = useState(false);

    const toggleMenu = () => {
        setMenuAberto(!menuAberto);
    };


    const fecharMenu = () => {
        setMenuAberto(false);
      };


    return (
        <div>
            <div className="center">
                <div className="div-esquerda">
                    <Link to={"/"} className="logo-a"><div className="imagem"></div>
                    </Link>
                    <div className="barra-separacao"></div>
                    <a href="https://instagram.com/leiloeiraelizamorim_?igshid=OGQ5ZDc2ODk2ZA==" id="icon-insta" className="icons" target="_blank"></a>
                    <a href="https://api.whatsapp.com/message/HYVEK4A4BUCXF1?autoload=1&app_absent=0" id="icon-wpp" className="icons" target="_blank"></a>
                    <a href="https://www.tiktok.com/@elizalmeida.leiloeira?_t=8fnOxeznacv&_r=1" id="icon-tik" className="icons" target="_blank"></a>
                </div>

                <div className="div-direita">
                    <header className="header">
                        <ul className="ul-header">
                            <li className="lista"><a href="https://aplleiloes.com.br/leiloes" className="link" target="_blank">Agenda</a></li>
                            <li className="lista"><a href="https://aplleiloes.com.br/cadastro" className="link" target="_blank">Cadastro</a></li>
                            <li className="lista"><Link to={"duvidas"} className="links">Dúvidas</Link></li>
                            <li className="lista"><Link to={"como-vender"} className="links">Como vender</Link></li>
                            <li className="lista"><Link to={"contatos"} className="links">Contato</Link></li>
                        </ul>
                    </header>
                </div>


                <div>
                    <div className="hamburguer-menu" onClick={toggleMenu}>
                        <div className="linhas"></div>
                        <div className="linhas"></div>
                        <div className="linhas"></div>
                    </div>

                    {menuAberto && (
                        <div className="menu-mobile">
                            <ul>
                                <li className="lista-mob"><a href="https://aplleiloes.com.br/leiloes" className="links-mob" target="_blank" onClick={fecharMenu}>Agenda</a></li>
                                <li className="lista-mob"><a href="https://aplleiloes.com.br/cadastro" className="links-mob" target="_blank" onClick={fecharMenu}>Cadastro</a></li>
                                <li className="lista-mob"><Link to={"duvidas"} className="links-mob" onClick={fecharMenu}>Dúvidas</Link></li>
                                <li className="lista-mob"><Link to={"como-vender"} className="links-mob" onClick={fecharMenu}>Como vender</Link></li>
                                <li className="lista-mob"><Link to={"contatos"} className="links-mob" onClick={fecharMenu}>Contato</Link></li>
                            </ul>
                        </div>
                    )}
                </div>

            </div>
            <Outlet />
            <div className="footer">
                <div className="footer-esquerdo">
                    <div className="colum1">
                        <h6 className="h6">Nossa <br />
                            empresa</h6>
                        <ul className="ul-colum">
                            <li className="link-colum"><Link to={"duvidas"} className="links-footer">Dúvidas</Link></li>
                            <li className="link-colum"><Link to={"como-vender"} className="links-footer">Como vender</Link></li>
                        </ul>
                    </div>
                    <div className="colum2">
                        <h6 className="h6">Informações</h6>
                        <ul className="ul-colum">
                            <li className="link-colum"><a href="/" className="links-footer">Política de privacidade</a></li>
                            <li className="link-colum"><a href="/" className="links-footer">Termos de Uso</a></li>
                            <li className="link-colum"><a href="/" className="links-footer">Documentos</a></li>
                            <li className="link-colum"><a href="/" className="links-footer">Mapa do site</a></li>
                        </ul>
                    </div>
                    <div className="colum3">
                        <h6 className="h6">Fale conosco</h6>
                        <ul className="ul-colum">
                            <li className="link-colum"><Link to={"contatos"} className="links-footer">Contato</Link></li>
                            <li className="link-colum"><a href="https://api.whatsapp.com/message/HYVEK4A4BUCXF1?autoload=1&app_absent=0" className="links-footer" target="_blank">(21) 97900-8112</a></li>
                        </ul>
                    </div>

                </div>
                <div className="footer-direito">
                    <Link to={"/"} className="logo-footer" ><div className="imagem-footer"></div>
                    </Link>
                    <br /><br />
                    <div className="footer-inferior">
                        <p className="siga">Siga-nos</p>
                        <div className="icons-proprio">
                            <a href="https://instagram.com/leiloeiraelizamorim_?igshid=OGQ5ZDc2ODk2ZA==" id="icon-insta-footer" className="icons" target="_blank"></a>
                            <a href="https://api.whatsapp.com/message/HYVEK4A4BUCXF1?autoload=1&app_absent=0" id="icon-wpp-footer" className="icons" target="_blank"></a>
                            <a href="https://www.tiktok.com/@elizalmeida.leiloeira?_t=8fnOxeznacv&_r=1" id="icon-tik-footer" className="icons" target="_blank"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Header;