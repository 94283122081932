import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/Home';
import reportWebVitals from './reportWebVitals';
import Contatos from './pages/contatos';
import Leiloeira from './pages/Leiloeira';
import Duvidas from './pages/duvidas';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Header from './componentes/header';
import Obrigado from './pages/obrigado';
import Vender from './pages/comoVender';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Header />,
    children: [
      {
        path: "/",
        element: <Leiloeira />
      },
      {
        path: "duvidas",
        element: <Duvidas />
      },
      {
        path: "contatos",
        element: <Contatos />,
      },
      {
        path: "obrigado",
        element: <Obrigado />
      },
      {
        path: "como-vender",
        element: <Vender/>
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
