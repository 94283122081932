import React from "react";
import "../style/obrigado.css"

function Obrigado () {
    return (
        <div className="background-obrigado">
            <div className="imagem-obrigado"></div>
            <h1>Obrigado pelo contato</h1>
            <p>Sua mensagem foi enviada, logo retornaremos</p>
        </div>
    )
}

export default Obrigado;