import React from "react";
import '../style/leiloeira.css'

function Leiloeira() {
    return (
        <div className="mae-leiloeira">
            <div className='caixa-home'>
                <div className='bloco-foto'>
                    <div className='background'>
                        <div className='texto-foto'>
                            <h3 className='h3 animacao'>ELIZABETH LEILOEIRA</h3>
                            <p className='p-meio animacao'>Entenda tudo sobre leilões</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="meio-leiloeira">
                <div className="texto-leiloeira">
                    <h3>LEILOEIRA</h3>
                    <div className="parte-inicio-leiloeira">
                        <div className="texto-meio-leiloeira">
                            <div className="img-leiloeira"></div>
                            <p>Elizabeth Amorim é uma leiloeira pública oficial registrada sob o número 317 da Jucerja.
                                Sua história é um exemplo de coragem, esforço e determinação. Seu caminho profissional começou no ramo de leilões, com um foco especial na venda de veículos.
                                Desde o início de sua carreira, Elizabeth demonstrou uma paixão inabalável por proporcionar ao seu público-alvo um amparo e um serviço de qualidade excepcional.<br></br><br></br>
                                Ela se destaca por sua dedicação em oferecer transparência e confiabilidade a todos aqueles que buscam oportunidades para adquirir bens a preços abaixo do que o mercado convencional oferece.
                                Detentora de um profundo conhecimento no campo de leilões, Elizabeth Amorim é reconhecida por sua expertise no setor. Sua integridade e comprometimento com a ética e as melhores práticas comerciais são notáveis, tornando-a uma referência no mundo dos leilões. Ela trabalha incansavelmente para garantir que todos os processos de leilão ocorram de maneira justa e transparente.
                            </p>
                        </div>
                        <div className="texto-meio-leiloeira">
                            <p>
                                Elizabeth é uma empreendedora que não apenas busca o sucesso pessoal, mas também se esforça para beneficiar seus clientes, oferecendo oportunidades únicas de adquirir bens de qualidade a preços acessíveis. <br></br><br></br>Seu compromisso com a excelência e sua paixão pelo mundo dos leilões a tornaram uma figura respeitada e admirada em sua área de atuação.
                                Com uma carreira sólida e um legado de integridade e comprometimento, Elizabeth Amorim continua a desempenhar um papel fundamental no mundo dos leilões, servindo como um exemplo inspirador para todos aqueles que desejam alcançar o sucesso por meio do trabalho árduo, da ética e do comprometimento com a satisfação do cliente.
                            </p>
                            <div className="img-leiloeira-2"></div>
                        </div>
                    </div>
                </div>





            </div>

        </div>
    );
}

export default Leiloeira;