import React from 'react';
import '../style/home.css';

function App() {
  return (
    <div className="App">
      <div className='mae'>
        <div className='caixa-home'>
          <div className='bloco-foto'>
            <div className='background'>
              <div className='texto-foto'>
                <h3 className='h3 animacao'>ELIZABETH LEILOEIRA</h3>
                <p className='p-meio animacao'>Entenda tudo sobre leilões</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default App;
